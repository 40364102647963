@import '../../layout/variables.scss';
.ant-input {
  font-size: 14px;
}
.ant-form-item {
  margin-bottom: 20px;
  &-row {
    flex-direction: column;
  }
  &-label {
    text-align: left;
    > label.ant-form-item-required {
      flex-direction: row-reverse;
      &:not(.ant-form-item-required-mark-optional) {
        &::before {
          color: $primary-red;
          font-size: 16px;
          margin-left: 3px;
        }
      }
    }
    ::after {
      display: none;
    }
  }
}
