@import '../../layout/variables.scss';
.filter-drawer {
  .ant-drawer-header {
    border-bottom: 1px solid $light-gray-300;
    padding: 40px 10px 40px 16px;
  }
  .ant-drawer-body {
    padding: 0;
    .filter-content {
      .search-bar {
        max-width: 100%;
       .ant-input-affix-wrapper {
          border-radius: 0;
          border-color: $light-gray-300;
          padding: 12px 8px 12px 16px;
          box-shadow: none;
        }
      }
      .ant-collapse {
        border: 0;
        .ant-collapse-item {
          border-color: $light-gray-300;
          .ant-collapse-header {
            color: $text-color;
            &:hover {
              color: $primary-pink;
            }
            .ant-collapse-extra {
              margin-top: -2px;
              button {
                width: 16px;
                height: 16px;
                border: 0;
                color: $primary-pink;
                span {
                  font-size: 12px;
                 }
              }
            }
          }
          .ant-collapse-content {
            &-box {
                border: 0;
            }
            .text-field {
              margin: 0;
            }
          }
        }
      }
    }
    .drawer-button {
      padding: 48px 16px 55px;
    }
  }
}