@import '../../layout/variables.scss';
.card-wrap {
  margin-bottom: 32px;
  .ant-card {
    &-head {
      padding: 0;
      border: none;
      border-radius: 0;
      &-wrapper {
        align-items: baseline;
      }
      &-title {
        font-weight: 700;
        padding: 0 0 16px 0;
      }
    }
    &-extra {
      padding-top: 0;
    }
    &-body {
      background-color: $light-red;
      border-radius: 10px;
      h3 {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .download-link {
        cursor: pointer;
        padding-bottom: 2px;
        transition: all 0.5s;
        color: $primary-pink;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.contract-document-modal {
  top: 20px;
}
.embed-image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 750px;
}