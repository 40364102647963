.description-button{
  margin-top: 5px;
  .ant-btn {
    margin-right:5px;
  }
}
.view-description{
    svg{cursor: pointer;}
}

.description-cell {
  pre {
    white-space: pre-wrap;
    overflow-y: auto;
    max-height: 70px;
    margin-bottom: 0;
  }
}

pre::-webkit-scrollbar {
width: 7px;
height: 10px;
}
pre::-webkit-scrollbar-track {
background: #f1f1f1;
}
pre::-webkit-scrollbar-thumb {
background: #00000040;
}