.ant-empty-description {
  width: 100%;
  max-width: 426px;
  margin: 0 auto;
  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
}
