.employeeDifferenceWarningModal {
  .value-container {
    padding: 20px 16px 20px 24px;
    h3 {
      margin-bottom: 6px;
    }
    .employee {
      margin-bottom: 18px;
    }
    .ant-badge {
      padding: 4px;
      &:first-child,
      &:last-child {
        margin: 0 -4px;
      }
      .ant-badge-count {
        font-size: 16px;
      }
    }
  }
}
