.rates-warning-modal {
  .value-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 16px 20px 24px;
    min-height: 300px;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    h3 {
      margin-bottom: 6px;
    }

    .rate,
    .special-rate {
      margin-bottom: 18px;
    }

    .btn-container {
      display: flex;
      justify-content: center;
    }

    .ant-badge {
      padding: 4px;

      &:first-child,
      &:last-child {
        margin: 0 -4px;
      }

      .ant-badge-count {
        font-size: 16px;
      }
    }
  }
}
