.verification-base-table {
  margin: 0 200px;
  cursor: pointer;
}
.table-title{
  div{
  display: flex;
  align-items: center;
  }
  button{
    margin-right: 15px;
  }
}
