@import '../../layout/variables.scss';
.apply-filter {
  min-width: 350px;
  min-height: 371px;
  background: $white;
  padding: 24px;
  box-shadow: 0px 6px 16px -8px rgba(0, 0, 0, 0.08),
    0px 9px 28px rgba(0, 0, 0, 0.05), 0px 12px 48px 16px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  &-head {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 25px;
    h2 {
      color: $text-color;
      font-weight: 700;
      margin: 0;
    }
    button {
      border: none;
      box-shadow: none;
      padding: 0;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  .text-field {
    margin-bottom: 28px;
  }
}
