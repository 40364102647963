@import '../../layout/variables.scss';
.new-company-info {
  h3 {
    margin: 0 0 10px;
  }
}
.exis_com-search {
  border-color: $primary-blue;
}
.company-drawer {
  .ant-drawer-body {
    padding: 0 30px;
  }
}

.email-label {
  width: 525px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  label::after {
    display: inline-block;
    font-family: SimSun, sans-serif;
    margin-right: 4px;
    line-height: 1;
    color: #de242b;
    font-size: 16px;
    margin-left: 3px;
    content: '*';
  }

  .email-add {
    font-size: 12px;
  }
}
