@import '../../layout/variables.scss';
.ant-layout-sider {
  padding-top: 40px;
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 999;
  .ant-menu {
    border: none;
    .ant-menu-item {
      padding-left: 0;
      margin: 0;
      width: 100%;
    }
  }
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
  margin: 0 0 14px 0;
  padding: 25px 0 25px 24px;
  font-size: 14px;
  line-height: 17px;
}
.ant-menu-inline {
  .ant-menu-item {
    &::after {
      content: '';
      left: 0;
      right: inherit;
      width: 13px;
      // width: 21px;
      height: 50px;
      background-image: url('../../assets/icons/sideractive.svg');
      background-size: cover;
      // background-size: auto 100%;
      // background-position: top right;
      background-repeat: no-repeat;
      border: none;
      animation: 0.3s active-side-menu-chevron-animation ease-in-out 0.33333s;
      animation-fill-mode: forwards;
      animation-delay: 1s;
    }
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $sharp-pink;
  font-weight: 600;
}
