@import '../../layout/variables.scss';
.ant-dropdown-show-arrow {
  .menu-list {
    ul.ant-dropdown-menu {
      box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
      border-radius: 6px;
      padding: 0 8px;
      margin: 0;
      li.ant-dropdown-menu-item {
        padding: 10px 0;
        border-bottom: 1px solid $gray-600;
        &:hover {
          background-color: $white;
        }
        &-selected {
          background: transparent;
        }
      }
    }
  }
}
