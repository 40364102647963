pending-leaves {
  margin-top: 24px;
  width: 100%;
}

.pending-leaves .ant-table-wrapper {
  width: 100%;
}

.pending-leaves .ant-table-cell {
  padding: 8px;
}

.pending-leaves .ant-table-thead > tr > th {
  font-weight: bold;
}

.pending-leaves .ant-table-tbody > tr > td {
  padding: 8px;
}

.pending-leaves .ant-table-tbody > tr:hover > td {
  background-color: #f5f5f5;
}
.add-button {
  margin-top: 20px;
}
