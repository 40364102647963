@import '../../layout/variables.scss';

.search-invoice-field {
  width: 100%;
  max-width: 400px;
  margin: 16px 0;
  .ant-select-selection-search-input {
    border: 1px solid $field-bg;
    border-radius: 4px;
    background-color: $field-bg;
    transition: all 0.5s;
    &:hover {
      border-color: $primary-light-pink;
      box-shadow: 0 0 0 2px $secondary-light-pink;
    }
  }
  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    box-shadow: none;
    outline: none;
  }
  .ant-input-wrapper {
    input {
      border: 0;
      padding: 5px 11px;
    }
  }
  button {
    border: 0;
    background-color: $field-bg;
  }
  span.ant-select-clear {
    opacity: 1;
    right: 36px;
  }
}

.search-invoice-field {
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    color: #616161;
    background-color: $field-bg;
    border: none;
  }
}
