.select-field {
  .ant-empty {
    display: none;
  }
}

.leave-balance-select {
  .ant-select-selector {
    min-width: 184px;
  }
}