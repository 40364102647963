@import '../../layout/variables.scss';
.filter-btn {
  background-color: $thin-gray;
  border: none;
  &:active,
  &:hover {
    background-color: $sharp-pink;
  }
  svg {
    vertical-align: middle;
  }
}