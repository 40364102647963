@import '../../layout/variables.scss';
.search-bar {
  width: 100%;
  max-width: 270px;
  .ant-form-item {
    max-width: 100%;
    margin: 0;
    .ant-input:hover {
      box-shadow: none;
    }
  }
  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper {
      padding-right: 8px;
      border-radius: 4px;
      border: 1px solid $gray;
      background: transparent;
      &:focus {
        border-color: $primary-light-pink;
      }
      .ant-input {
        background: transparent;
      }
    }
    .ant-input-prefix {
      order: 1;
      cursor: pointer;
      margin-right: 0;
    }
  }
}
