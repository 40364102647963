.invoice-table-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.invoice-select-sidebar {
  height: 600px !important;
  padding-top: 0 !important;
}

.invoice-timesheet-table {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 70vh;
  min-height: 60vh;
  overflow: scroll;
  padding: 5px;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .verify-btn {
    margin: 14px;
    align-self: center;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 6px;
  }
}

.invoice-timesheet-table::-webkit-scrollbar {
  display: none;
}

.light-red {
  color: orange;
}

.dark-red {
  color: rgb(229, 19, 19);
}
.green {
  color: rgb(49, 164, 51);
}

.timesheet-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.timesheet-name {
  margin: 0 15px;
  font-size: medium;
  font-weight: 600;
}

.invoice-collpase {
  border: none;
}

.ant-collapse {
  border: none;
  .ant-collapse-item {
    border: none;
    .ant-collapse-header {
      margin: 15px 15px 0 !important;
      border: none;
    }
    .ant-collapse-content-box {
      min-height: 0;
      margin: 15px 15px 0;
    }
  }
}
.add-row-btn {
  margin: 0px 15px 5px;
}
.select-field {
  width: 100%;
}
.color-info {
  display: flex;
  align-items: center;
  gap: 5;
}

.timesheet-action {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.timesheet-action button {
  border: none; /* Remove button border */
  margin-bottom: 8px; /* Add margin between buttons */
  padding: 0;
}

.download-btn-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-summary {
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: white;
  .ant-table-cell {
    padding: 8px 6px;
  }
}
.verification-notes {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
  gap: 10px; /* Adjust gap as needed */
  textarea {
    width: 90% !important;
    overflow-y: scroll;
  }
  .ant-select-selection-overflow {
    min-height: 76px;
    max-height: 76px; 
    overflow-y: auto;
    padding: 4px 11px;
  }
}

.billing-percentage {
  .ant-input {
    width: 60%;
  }
}
.note-title {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.custom-title {
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
}
