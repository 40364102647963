@import '../../layout/variables.scss';
.no-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  background-color: $white;
  border-radius: 10px;
  & > div {
    text-align: center;
    h1 {
      font-size: 52px;
      line-height: 52px;
      margin: 0;
    }
    h4 {
      font-size: 18px;
      line-height: 18px;
      color: $text-color;
    }
    p {
      font-size: 14px;
      line-height: 14px;
      color: $gray;
    }
  }
  .no-client-details {
    padding: 20px;
    button {
      margin: 10px;
    }
  }
}