.download-timesheet-modal {
  .value-container {
    padding: 20px 16px 20px 24px;
    h3 {
      margin-bottom: 6px;
    }
    .container-item {
      margin-bottom: 18px;
    }
  }
}
