.week-table-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.week-select-sidebar {
  height: 600px !important;
  padding-top: 0 !important;
}

.week-timesheet-table {
  margin-left: 155px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 675px;
  .verify-btn {
    margin: 14px;
    align-self: center;
  }
}

.verified-employee-row {
  background-color: rgba(145, 234, 120, 0.5);
}

.unverified-employee-row {
  background-color: rgba(238, 126, 126, 0.5);
}

.light-red {
  color: rgb(247, 87, 87);
}

.dark-red {
  color: rgb(229, 19, 19);
}
.green {
  color: rgb(49, 164, 51);
}

.verification-notes {
  margin-top: 15px;
  label {
    font-size: 14px;
    font-weight: 600;
    color: #3d3d3d;
  }

  textarea {
    width: 40%;
    overflow-y: scroll;
  }
}
.table-summary {
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: white;
  .ant-table-cell {
    padding: 8px 6px;
  }
}
