@import '../../layout/variables.scss';

.generate-invoice-modal-container {
  top: 5%;
}

.steps-modal-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.steps-container {
  padding: 0 10px 20px;
  width: 60%;
  position: relative;
}
.step-content-wrapper {
  border: 2px solid $secondary-light-pink;
  width: 100%;
  overflow: hidden;
  min-height: 65vh;
  max-height: 100%;
}
.steps-footer {
  margin-top: 20px;
  align-self: flex-end;
  position: absolute;
  bottom: 20px;
}
