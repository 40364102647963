.auth-wrap {
  height: 100vh;
  &-left {
    height: 100vh;
    padding: 48px;
    background-color: $light-gray;
    &-image {
      position: relative;
      height: 100%;
      .logo {
        position: absolute;
        top: 0;
        left: 0;
      }
      .auth-bg-img {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &-right {
    padding: 48px 93px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    height: 100vh;
    overflow: auto;
    background-color: $white;
    @include xmd {
      padding: 48px 35px;
    }
    &-content {
      width: 100%;
      max-width: 401px;
      h1 {
        font-weight: 700;
        margin-bottom: 12px;
        line-height: 44px;
        @include md {
          font-size: 25px;
        }
      }
      p {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
      }
      form {
        max-width: 400px;
        .ant-form-item {
          margin-bottom: 20px;
          &-label {
            padding: 0 0 6px;
            line-height: 15px;
          }
          .ant-input:hover,
          .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
          .ant-input-affix-wrapper:focus,
          .ant-input-affix-wrapper-focused .ant-input:hover,
          .ant-input:focus,
          .ant-input-focused,
          .ant-input-affix-wrapper-focused {
            border-color: $primary-light-pink;
            box-shadow: 0 0 0 2px $secondary-light-pink;
            outline: none;
          }
        }
      }
      .auth-action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 23px 0 50px;
        .ant-checkbox-wrapper {
          align-items: flex-start;
        }
        a {
          color: $primary-light-pink;
        }
      }
      .submit {
        margin-bottom: 0;
      }
      .back-btn-wrap {
        text-align: center;
        button {
          border: 0;
          box-shadow: none;
          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.otp-wrap {
  padding: 40px;
  text-align: center;
  h2 {
    color: $text-color;
    font-weight: 400;
  }
  .otp-input-wrap {
    margin-bottom: 40px;
    .otp-input-field {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      border: 1px solid $light-text;
      border-radius: 8px;
      color: $primary-light-pink;
      outline: none;
      &:hover,
      &:focus {
        border-color: $primary-light-pink;
        box-shadow: 0 0 0 3px $secondary-light-pink;
      }
    }
  }
  .verify-otp-btn {
    margin-bottom: 20px;
  }
}
.contact-support-wrap {
  .logo {
    margin-bottom: 10px;
  }
  button {
    border: 0;
  }
  .contact-support-heading {
    display: flex;
    align-items: baseline;
    justify-content: center;
    span {
      margin-right: 15px;
    }
  }
  .contact-support {
    margin-bottom: 50px;
  }
}
.ant-form-item-explain-error {
  font-size: 10px;
}