@import '../../layout/variables.scss';
.ant-modal-wrap {
  overflow: hidden;
  .modal-wrap {
    .ant-modal-header {
      border-radius: 10px 10px 0 0;
      .ant-modal-title {
        color: $primary-pink;
        font-size: 20px;
      }
    }
    .ant-modal-body {
      padding: 0;
      .modal-content {
        .ant-input-group-compact {
          display: flex;
          .search-popup-text,
          .search-project-bar,
          button {
            border: none;
            padding: 0;
            border-radius: 0;
            .ant-select-selector {
              height: 100%;
              line-height: 40px;
              input {
                border: none;
                background-color: transparent;
                padding: 15px 0;
                &:hover,
                &:focus {
                  box-shadow: none;
                }
              }
              &:hover {
                box-shadow: none;
              }
            }
          }
          .search-popup-text {
            flex: 1 1 0;
            .ant-input-group {
              display: flex;
              flex-direction: row-reverse;
              .ant-input-group-addon {
                width: 40px;
                background: url('../../assets/icons/searchicon.svg') no-repeat
                  center;
                button {
                  display: none;
                }
              }
            }
          }
          .search-project-bar {
            margin-right: 40px;
            flex: 0 0 200px;
            border-right: 1px solid $field-bg;
            border-left: 1px solid $field-bg;
            box-shadow: none !important;
            .ant-select-selector {
              background: transparent !important;
              border: none !important;
              box-shadow: none !important;
              .ant-select-selection-placeholder,
              .ant-select-selection-item {
                display: flex;
                align-items: center;
              }
            }
            .ant-select-arrow {
              margin-top: 25px;
            }
          }
        }
      }
    }
  }
}

.search-project {
  max-width: 100%;
  border-bottom: 1px solid $primary-light-pink;
  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper,
    .ant-input-affix-wrapper-focused {
      border: none;
      border-radius: 0;
      box-shadow: none;
      .ant-input-prefix {
        order: 0;
        margin-right: 4px;
      }
    }
  }
}
.ant-modal-close-x {
  background: url(/static/media/close.69104389.svg) no-repeat center;
  width: 40px;
  height: 52px;
  svg {
    display: none;
  }
}
.ant-select-item-option {
  &:hover {
    background-color: $light-pink;
    color: $primary-pink;
  }
}
