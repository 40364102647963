@import "../../layout/variables.scss";
.smallcard-wrap {
  .ant-card {
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    .ant-card-body {
      padding: 27px 16px 22px 8px;
      .ant-card-meta {
        display: flex;
        margin: 0;
        align-items: center;
        .ant-card-meta-avatar {
          .ant-avatar {
            width: 48px;
            height: 48px;
          }
        }
        .ant-card-meta-title {
          font-weight: 700;
          font-size: 40px;
          margin: 0;
          line-height: 48px;
        }
        .ant-card-meta-description {
          color: $secondary-light;
          font-size: 16px;
          font-weight: 600;
          white-space: nowrap;
          line-height: 19px;
        }
      }
    }
  }
}
