.date-field {
  width: 100%;
  .ant-picker-suffix {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    background: url('../../assets/icons/calender.svg') no-repeat center;
    svg {
      display: none;
    }
    &:hover {
      background: url('../../assets/icons/close.svg') no-repeat center; /* replace with your clear icon */
      cursor: pointer;
    }
  }
  .ant-picker-clear {
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
    display: inline-block;
  }
}
.date-picker-dropdown {
  .ant-picker-date-panel,
  .ant-picker-month-panel {
    width: 100%;
    table.ant-picker-content {
      width: 100%;
    }
  }
}
