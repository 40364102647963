@import '../../layout/variables.scss';
.auto-select-capitalize {
  .ant-select-item-option-content {
    text-transform: uppercase;
  }
}
.radio-group {
  width: 100%;
}
.detete-btn {
  margin: 0 0 0 10px;
  label {
    margin-right: 0;
  }  
}
.active {
  border-color: $primary-pink;
}