@import '../../layout/variables.scss';
.upload-field {
  .ant-form-item-control-input {
    background-color: $field-bg;
    border: 1px solid $field-bg;
    border-radius: 4px;
    transition: all 0.3s;
    &:hover {
      border-color: $blue;
      box-shadow: 0 0 0 3px $primary-blue;
    }
    .ant-form-item-control-input-content {
      span {
        display: flex;
        align-items: center;
        .ant-upload-list {
          flex: 1 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space:nowrap;
          &-item {
            margin: 0;
            &-name {
                color: $text-color;
            }
          }
        }
        .ant-upload-text-icon {
          display: none;
        }
      }
      button {
        width: 24px;
        height: 24px;
      }
    }
  }
}
