$blue: #296df1;
$info: #f1f2f3;
$gray: #aeaeae;
$white: #ffffff;
$field-bg: #f4f4f4;
$body-bg: #f5f5f5;
$gray-600: #e8eaec;
$thin-gray: #edeeef;
$light-red: #f8f8f8;
$sharp-pink: #fff7f8;
$gray-shade: #f9f9f9;
$light-pink: #fff6f8;
$gray-scale: #efefef;
$text-color: #3d3d3d;
$light-text: #8b8b8b;
$light-gray: #fff9f9;
$light-green: #32d670;
$primary-red: #de242b;
$primary-dark: #17233d;
$primary-blue: #e9f3ff;
$primary-pink: #ef5366;
$primary-light-pink: #fc818b;
$secondary-light-pink: #fff0f0;
$secondary-light: #c1c1c1;
$dark-gray: #4d4d4d;
$black: #000000;
$warning: #ff4d4f;
$light-gray-300: #ebebeb;
$warning-yellow: #faad14;
$white-secondary: #fafafa;
$orange:#ff8c00 ;
$breakpoints: (
  'sm': 576px,
  'md': 768px,
  'xmd': 992px,
  'lg': 1024px,
  'xl': 1260px,
  'xxl': 1439px,
);
@mixin sm {
  @media (max-width: map-get($breakpoints, 'sm')) {
    @content;
  }
}
@mixin md {
  @media (max-width: map-get($breakpoints, 'md')) {
    @content;
  }
}
@mixin xmd {
  @media (max-width: map-get($breakpoints, 'xmd')) {
    @content;
  }
}
@mixin lg {
  @media (max-width: map-get($breakpoints, 'lg')) {
    @content;
  }
}
@mixin xl {
  @media (max-width: map-get($breakpoints, 'xl')) {
    @content;
  }
}
@mixin xxl {
  @media (max-width: map-get($breakpoints, 'xxl')) {
    @content;
  }
}
