.not-found-message {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.create-btn-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .save-in-quickbook-btn {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}
.invoice-table {
  padding: 30px;
  max-height: 675px;
  overflow-y: auto;
}

.quickbook-data-table {
  .add-new-row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    button{
      display: flex;
    }
  }
}
.back-btn {
  margin: 20px 0 0 20px;
}
.prev-btn {
  margin: 0 0 -10px 24px;
}
.quickbook-link {
  padding: 20px;
}
