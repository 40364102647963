@import '../../layout/variables.scss';
.filter-popup {
  padding: 20px;
  .filter-btn-group {
    display: flex;
    justify-content: space-between;
  }
  .ant-picker {
    &-focused,
    &:hover {
      border-color: $primary-light-pink;
      box-shadow: 0 0 0 2px $secondary-light-pink;
    }    
  }
}