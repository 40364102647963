.selected-options {
  border: 1px solid #ef5966;
  border-radius: 4px;
  padding: 2px 5px;
  margin: 2px 8px 2px 0px;
  .close {
    margin-left: 5px;
    cursor: pointer;
  }
  span{
    color: #3d3d3d;
  }
}
.multiple-projects {
  .ant-select-selection-overflow {
    max-height: 130px; 
    overflow-y: auto;
  }
}
