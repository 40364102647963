@import '../../layout/variables.scss';
.list-items-wrap {
  .ant-collapse {
    border: none;
    .ant-collapse-item {
      border: none;
    }
    .ant-collapse-header {
      color: $dark-gray;
      font-weight: 700;
      font-size: 16px;
    }
    .activity-title {
      span,
      a {
        color: $primary-pink;
        font-size: 14px;
      }
      a {
        margin: 0 5px;
      }
      .project-name {
        margin-left: 5px;
      }
      .activity-username {
        font-weight: 700;
      }
    }
    .link-btn {
      padding: 0 4px;
      span {
        color: $text-color;
        font-weight: 700;
      }
    }
    .activity-discription {
      border: 1px solid $thin-gray;
      border-radius: 4px;
      padding: 15px;
      .activity-log {
        margin-bottom: 10px;
        p {
          margin-bottom: 5px;
        }
      }
    }
  }
}