@import '../../layout/variables.scss';
.edit-profile {
  .upload-image {
    text-align: center;
    .edit-profile-image {
      position: relative;
      .ant-image {
        position: absolute;
        right: 5px;
        bottom: 0;
      }
    }
  }
  .ant-form-item {
    &-label {
      padding: 0;
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border-color: $blue;
      box-shadow: 0 0 0 2px $primary-blue;
    }
  }
}