@import '../../layout/variables.scss';
.ant-table-footer {
  border-radius: 0 0 10px 10px;
  .ant-pagination {
    font-size: 12px;
    .ant-pagination-next,
    .ant-pagination-prev {
      .ant-pagination-item-link {
        border: none;
        background-color: $primary-pink;
        color: $white;
      }
      &.ant-pagination-disabled {
        .ant-pagination-item-link {
          background-color: $gray-shade;
          color: $gray;
        }
      }
    }
    .ant-pagination-item {
      border: none;
      background-color: transparent;
    }
    .ant-pagination-options {
      margin-left: 6px;
      .ant-select {
        font-size: 10px;
        min-width: 100px;
      }
      .ant-select-suffix {
        height: 7px;
        width: 9px;
        background: url('../../assets/icons/selectdownarrow.svg') no-repeat center;
        svg {
          display: none;
        }
      }
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector,
      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: $primary-light-pink;
        box-shadow: 0 0 0 2px $secondary-light-pink;
      }
    }
  }
}

