@import '../../layout/variables.scss';

.note-status-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-description {
  margin-bottom: 0;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
