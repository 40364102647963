.divider-text {
  font-size: 15px;
  margin: 0;
  color: rgb(30, 28, 28);
}
.client-information {
  .ant-divider-horizontal.ant-divider-with-text::before,
  .ant-divider-horizontal.ant-divider-with-text::after {
    position: unset;
  }
}
