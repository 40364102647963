@import '../../layout/variables.scss';
.header-wrap {
  display: block;
  height: 56px;
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      line-height: 0;
      margin-right: 80px;
    }
    .page-nav {
      width: 100%;
      @include xl {
        width: 60%;
      }
      @include lg {
        width: 40%;
      }
      @include md {
        width: 30%;
      }
    }
    .ant-menu-horizontal {
      border: none;
    }
  }
  .ant-menu-horizontal {
    border: none;
    > .ant-menu-item {
      &::after {
        border: none;
      }
    }
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    &::after {
      border: none;
    }
    &:hover {
      border: none;
    }
  }

  .page-action {
    .list-items {
      display: flex;
      align-items: center;
      gap: 25px;
      .search-btn {
        border: 0;
        svg {
          width: 30px;
          height: 26px;
        }
      }
      .bell-icon {
        height: 23px;
        position: relative;
        cursor: pointer;
        .notifi-dot {
          content: '';
          width: 12px;
          height: 12px;
          background-color: $primary-red;
          border-radius: 50%;
          position: absolute;
          top: -5px;
          right: -2px;
        }
      }
    }
  }
}
.drop-icon {
  svg {
    height: 15px;
    width: 15px;
    vertical-align: middle;
  }
}
.ant-dropdown-menu {
  margin-top: 19px;
  padding: 10px 0 10px 0;
}
.ant-dropdown-menu-item,.ant-dropdown-menu-submenu-title {
  padding: 8px 20px;
  color: $text-color;
}
.ant-dropdown-menu-submenu-title {
  display: flex;
}
.ant-layout-header {
  position: fixed;
  width: 100%;
  z-index: 999;
  @include md {
    padding-right: 24px;
  }
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: $light-pink;
  color: $primary-pink;
  transition: 0.3s;
}
.ant-dropdown-menu-title-content {
  .logout-menu-item,
  a {
    display: flex;
    align-items: center;
    .dropdown-icons {
      height: 22px;
      margin-right: 10px;
      svg {
        vertical-align: middle;
      }
    }
  }
}
.select-menu {
  .ant-input-affix-wrapper {
    padding-bottom: 6px;
  }
}