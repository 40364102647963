.xlarge-btn {
  height: 50px;
  font-size: 16px;
}

.X-large-input-field {
  height: 40px;
}

.text-center {
  text-align: center;
}

.text-field {
  .ant-form-item-label {
    padding: 0 0 6px;
    line-height: 15px;
  }

  textarea {
    height: 33px;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input:focus,
.ant-input-focused,
.ant-picker:hover,
.ant-input:hover,
.ant-picker-focused,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $blue;
  box-shadow: 0 0 0 3px $primary-blue;
  border-radius: 4px;
}

.title {
  color: $light-text;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;

  @include md {
    font-size: 12px;
  }
}

.content-wrap {
  background-color: $white;
  border-radius: 10px;

  .project-overview {
    background-color: $light-pink;
    border-radius: 10px 10px 0px 0px;
    padding: 29px 63px 29px 40px;

    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .project-info {
    padding: 40px 63px 40px 36px;
  }
}

.update-btn-wrap {
  text-align: end;

  button {
    width: 24px;
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0;

    &:not([disabled]):hover,
    &:not([disabled]):active {
      border: none;
      background-color: transparent;
    }
  }
}

[ant-click-animating-without-extra-node='true'] {
  &::after {
    display: none;
  }
}

.ant-select-dropdown {
  .ant-select-dropdown-placement-bottomLeft {
    .ant-select-dropdown-hidden {
      padding: 0;
    }
  }
}

.ant-input-number-out-of-range input {
  color: $gray;
}

.ant-layout {
  height: 100%;

  .site-layout-background {
    padding: 40px;

    @include xmd {
      padding: 20px;
    }
  }
}

.page-wrapper {
  height: calc(100vh - 56px);
  overflow: auto;
}

.page-content {
  margin-left: 200px;
  height: 100%;
}

.no-header-page-wrapper {
  margin-top: 0;

  .page-content {
    margin: 0;

    .ant-layout-content {
      padding: 0;
    }
  }
}

.pageheader-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  padding: 32px 40px 24px 18px;
  border-radius: 10px 10px 0 0;

  @include md {
    padding-right: 18px;
  }

  h3 {
    color: $primary-red;
    font-size: 20px;
  }

  .pageheader-right {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.add-project {
  .ant-collapse {
    border: none;

    & > .ant-collapse-item {
      border: none;
      border-top: 1px solid $info;

      & > .ant-collapse-header {
        padding: 27px 0 0;
        margin-bottom: 16px;
        color: $text-color;
        border: none;

        .ant-collapse-header-text {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .ant-collapse-content {
        border: none;

        &-box {
          padding: 0;
        }
      }
    }

    .ant-collapse-arrow {
      height: 20px;
      width: 20px;
      background: url('../assets/icons/down-arrow.svg') no-repeat center;

      svg {
        display: none;
      }
    }

    .ant-collapse-item.ant-collapse-item-active {
      .ant-collapse-expand-icon {
        .ant-collapse-arrow {
          transform: translateY(0) rotate(180deg);
        }
      }
    }
  }
}

.ant-select-arrow {
  top: 0;
  margin-top: 14px;
  background-image: url('../assets/icons/selectdownarrow.svg');
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;

  .anticon {
    display: none;
  }
}

.badge-wrap {
  .badge {
    padding: 2px 7px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: $white;
    border-radius: 16px;
    text-transform: capitalize;
  }
}

.success-color {
  color: $light-green;
}

.danger-color {
  color: $primary-red;
}
.inProgress-bg {
  background: $primary-light-pink;
}
.gray-bg {
  background: $gray;
}
.success-bg {
  background: $light-green;
}

.danger-bg {
  background: $primary-red;
}

.warning-bg {
  background: $warning-yellow;
}
.due-bg {
  background: $orange;
}

.btn-group {
  margin-left: auto;
  border: none;
  text-align: end;

  button {
    margin-left: 16px;
  }
}

.add-package {
  padding: 0;
  font-size: 12px;

  svg {
    vertical-align: middle;
  }

  span {
    margin-left: 4px;
  }
}

.card {
  background: $white;
  padding: 24px;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 3%);
  border-radius: 10px;
  height: 100%;

  .card-header {
    display: flex;
    justify-content: space-between;

    .left-content {
      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $dark-gray;
      }

      h5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: $light-text;
        margin-top: 4px;
      }
    }
  }
}

.invoice-prediction-details {
  border-top: 1px solid $info;
  padding: 22px 0;

  h3 {
    margin-bottom: 16px;
  }
}

.detete-icon {
  margin: auto 0;
  border: none;
}

.invalid-file-size {
  margin: 0;
}

.project-list-wrap,
.prediction-list-wrap,
.prediction-details,
.invoice-details,
.salespersons-list-wrap,
.company-list-wrap {
  .ant-table-tbody {
    tr {
      cursor: pointer;
    }
  }
}

.file-upload-warning {
  display: inline-block;
  color: $warning;
  margin-top: -18px;
}

.generate-invoice-btn {
  font-size: 12px;
  padding: 3px 11px;
  height: 28px;
  color: $primary-pink;
}

.fallback-spin {
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-border-none {
  padding: 0;
  border: 0;
  box-shadow: none;
}

.pageheader-right-auto {
  margin-left: auto;
}

.select-field-with-button {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .text-field {
    flex: 1;
  }

  svg {
    vertical-align: inherit;
  }
}

.company-info {
  .project-overview {
    padding: 40px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.tooltip {
  .ant-tooltip-inner {
    max-height: 300px;
    overflow: auto;
    color: $primary-pink;
    font-size: 15px;
    line-height: 20px;
    .invoice-data {
      .text-field {
        margin: 0;
      }
    }
  }
}

.addedit_usermodule {
  .modal-content {
    text-align: center;
    padding: 40px 20px 20px;

    p {
      margin-bottom: 20px;
    }
  }
}

.ant-table-filter-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        display: flex;

        & > span {
          display: inline-block;
          max-width: 220px;
        }
      }
    }
  }
}

.import-status-sync {
  .prev-first-time {
    font-size: 12px;
  }

  .status {
    text-transform: capitalize;
    color: $primary-red;

    &-Successful {
      color: $light-green;
    }
  }
}

.bold-text {
  font-weight: 600;
}

.danger-text {
  color: red;
}

.green-text {
  color: green;
}

.grey-text {
  color: grey;
}

.table-title {
  display: flex;
  justify-content: space-between;
}

.select-company {
  .ant-select-selection-item,
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }
}

.highlighted-row {
  background-color: white;
  animation-name: test;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes test {
  from {
    background-color: $primary-light-pink;
  }

  to {
    background-color: $secondary-light-pink;
  }
}
.ant-select {
  &.warning-select-bg {
    .ant-select-selector {
      background: $warning-yellow;
    }
  }
  &.success-select-bg {
    .ant-select-selector {
      background: $light-green;
    }
  }
}
