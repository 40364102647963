@import '../../layout/variables.scss';
.invoice-received-wrapper {
  .ant-drawer-mask {
    position: fixed;
    background-color: $body-bg;
  }
  .ant-drawer-content {
    border-radius: 10px;
    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        padding: 32px 20px;
        border-bottom: 1px solid $field-bg;
        margin-bottom: 20px;
        .ant-drawer-title {
          color: $primary-pink;
          font-size: 20px;
          font-weight: 600;
        }
      }
      .text-field {
        margin: 0;
      }
      .ant-drawer-body {
        padding: 0 20px;
      }
    }
  }
}
