@import '../../layout/variables.scss';
.not-found {
  height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
  &-content {
    text-align: center;
    .not-found-404 {
      height: 200px;
      line-height: 200px;
      h1 {
        font-size: 168px;
      }
    }
    h2 {
      font-weight: 400;
      font-size: 22px;
      color: $dark-gray;
    }
  }
}
