@import '../../layout/variables.scss';
.ant-table-column-title {
  font-weight: 600;
}
.ant-table {
  border-radius: 10px;
  .ant-table-tbody > tr > td {
    padding: 8px 6px;
  }
}
.ant-table-column-sorters {
  align-items: center;
  justify-content: unset;
  gap: 6px;
}
.ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: none;
}
