@import '../../layout/variables.scss';
.emptyBillingWarningModal {
  .value-container {
    padding: 20px 16px 20px 24px;
    h3 {
      margin-bottom: 6px;
    }
    .employee {
      margin-bottom: 18px;
    }
    .ant-badge {
      padding: 4px;
      &:first-child,
      &:last-child {
        margin: 0 -4px;
      }
      .ant-badge-count {
        font-size: 16px;
      }
    }
  }
  .ant-btn {
    border: none;
    background: none;
    padding: 0;
    margin: 0 5px 0 0;
    font: inherit;
    cursor: pointer;
    color: $primary-pink;

  }

  .ant-btn:hover,
  .ant-btn:focus {
    text-decoration: underline; 
  }

  .button-link:focus {
    outline: none;
  }
}
