@import '../../layout/variables.scss';
.drawer-wrapper {
  .ant-drawer-header {
    border: 0;
    .ant-drawer-header-title {
      flex-direction: row-reverse;
      .ant-drawer-title {
        color: $primary-dark;
        font-weight: 400;
      }
      .ant-drawer-close {
        width: 12px;
        height: 12px;
        background: url('../../assets/icons/close.svg') no-repeat center;
        svg {
          display: none;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0 40px;
    h3 {
      font-weight: 700;
    }
    .drawer-button {
      padding: 48px 0 55px;
    }
  }
}
